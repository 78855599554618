import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Columns from "./columns"
import Column from "./column"
import RotateLetter from "./rotate-letter"

const StepsBox = ({ step, title, children }) => {
  return (
    <Box bg="muted" p={[6, 6, 8]}>
      <Columns collapse={[true, false]}>
        <Column width="content">
          <RotateLetter size={12} fontSize={6}>
            {step}
          </RotateLetter>
        </Column>
        <Column>{children}</Column>
      </Columns>
    </Box>
  )
}

StepsBox.propTypes = {
  step: PropTypes.number,
}

StepsBox.defaultProps = {
  step: 1,
}

export default StepsBox
