import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Paper from "../../../components/paper"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopDezemberPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/dezember/materialien.jpg" }
      ) {
        ...largeImage
      }
      vorlage: file(relativePath: { eq: "vorlage-dezember.pdf" }) {
        publicURL
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/dezember/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/dezember/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-dezember">
      <Seo
        title="Dezember"
        description="Ho, Ho, Ho! Dezember ist der Monat des Weihnachtsfestes, des Aneinander-Denkens und Zusammenseins mit den Liebsten. Für diesen letzten Monat des Jahres bereiten wir Nikolaus-Socken vor und verstecken darin Weihnachts-Wörter und -Assoziationen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "Dezember",
              link: "/workshops/kalender/dezember",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>Dezember</PageTitle>
            <Paragraph dropcap={true}>
              Ho, Ho, Ho! Dezember ist der Monat des Weihnachtsfestes, des
              Aneinander-Denkens und Zusammenseins mit den Liebsten. Für diesen
              letzten Monat des Jahres bereiten wir Nikolaus-Socken vor und
              verstecken darin Weihnachts-Wörter und -Assoziationen.
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Stack>
                <Heading as="h2" level={2}>
                  Du brauchst...
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Eine Malvorlage für die Nikolaus-Socken. Du findest sie in
                      deinem Bastelset oder kannst sie{" "}
                      <TextLink href={data.vorlage.publicURL}>
                        dir hier ausdrucken
                      </TextLink>
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Kleine Zettel zum Beschreiben
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Einen Wollfaden
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-4">
                    <Text as="span" size={4}>
                      Einige Buntstifte zum Bemalen
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-5">
                    <Text as="span" size={4}>
                      Eine Schere und einen Klebestift
                    </Text>
                  </Checkbox>
                </Stack>
                <Image
                  image={data.materialien.childImageSharp.gatsbyImageData}
                  alt="Die Anleitung zum Kalenderblatt mit den Buchstaben S,O,M,M,E,R zum Ausschneiden"
                />
              </Stack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Male die Nikolaus-Socken zunächst farbig aus.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={2}>
                  <Paragraph>Anschließend schneidest du sie aus.</Paragraph>
                </StepsBox>
                <StepsBox step={3}>
                  <Paragraph>
                    Schneide dir ein paar kleine Streifen Papier zurecht und
                    schreibe auf jeden von ihnen ein Wort, das dir zum Monat
                    Dezember einfällt.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={4}>
                  <Paragraph>
                    Klebe den Wollfaden wie eine Girlande auf deine
                    Kalenderseite.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={5}>
                  <Paragraph>
                    Verteile die Socken auf der Schnur, stecke die
                    Papierstreifen mit deinen Wörtern dahinter und klebe den
                    Rand der Socken so fest, dass man die Zettel noch dahinter
                    hervorziehen kann.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={6}>
                  <Paragraph>
                    Und fertig ist deine letzte Kalenderseite.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3}>
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Detailansicht der fertigen Kalenderseite. Bunte Buchstaben und ein handgeschriebenes Gedicht"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Ein weitere Detailansicht der fertigen Kalenderseite. Der Buchstabe E ist fokussiert"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Du willst wissen, wie Hölderlin Weihnachten verbracht hat?
              </Heading>
              <Paragraph>
                Als Schüler ging Hölderlin auf die Klosterschule in Denkendorf
                und später in Maulbronn. Die meiste Zeit des Jahres verbrachten
                die Schüler dort vor Ort. So auch die Weihnachtstage. In einem
                Brief aus dem Jahr 1785 schreibt der 15-jährige Hölderlin an
                seine Mutter:
              </Paragraph>
              <AudioPlayer src={`workshops/kalender/dezember`} />
              <Paper>
                <Paragraph>
                  »Liebste Mamma! Wann dißmal mein Brief etwas verworrener ist
                  als sonst, so müssen Sie eben denken, mein Kopf sei auch von
                  Weinachtsgeschäften eingenommen, wie der Ihrige — doch
                  [unterscheiden] sie ein wenig: meine sind Pläne auf die Rede,
                  die ich am Johannistage bei der Vesper halte, tausend Entwürfe
                  zu Gedichten, die ich in den Cessationen (vier Wochen, wo man
                  bloß für sich schafft) machen will [...], ganze Pakete von
                  Briefen, die ich, ob schon das neue Jahr wenig dazu beiträgt,
                  schreiben muß... Was die Besuche in den Weinachten betrifft,
                  so bin ich eher so frei, Sie hieher einzuladen, weil mich das
                  Geschäfft am Johannistage, wie gesagt, nicht leicht abkommen
                  läßt. Die [lieben] Geschwisterige werden sich wieder recht
                  freuen; aber, im Vertrauen gesagt, mir ists halb und halb
                  bange, wie sie von mir beschenkt werden sollen [...] Der
                  [lieben] Frau Grosmamma mein Compliment, und ich wolle Ihr
                  auch ein Weinachts-Geschenk machen ich wolle dem lieben Gott
                  mit rechter Christtags-Freude danken, daß er Sie mir auch
                  dieses beynahe vollendte Jahr wieder so gesund erhalten habe.«
                </Paragraph>
              </Paper>
              <Paragraph>
                Hölderlin hat die Weihnachtstage also über Schularbeiten,
                Gedichten und Briefen verbracht. Das hat er auch später als
                Erwachsener so beibehalten. Meist saß er über die Weihnachtstage
                am Schreibtisch und arbeitete an seinen Werken. Manchmal war er
                auch auf Reisen. So zum Beispiel im Winter 1801, als er ganz
                alleine den weiten Weg von Straßburg bis nach Bordeaux durch
                verschneite Gebirge und gefährliche Landschaften zurückgelegt
                hat.
              </Paragraph>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
